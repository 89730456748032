<template>
  <div>
    <div class='navbar'>
      <div class="nav-title">{{ title }}</div>
    </div>
    <div class="slot"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {},
}
</script>

<style lang='scss' scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 44px;
  line-height: 44px;
  background: #fff;
  text-align: center;

  .nav-title {
    text-align: center;
    max-width: 200px;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

}
  .slot {
    height: 44px;
  }
</style>