<template>
	<div class="lottery-box">
		<!-- <navbar title="抽奖" /> -->
		<!-- <image class="swiper-img"  src="../assets/lottery-title.png" mode=""></image> -->
		<div class="swiper-img">

		</div>
		<!-- 规则按钮 -->
		<!-- <div class="rule-wrap" @click="showRules = true">luật lệ</div> -->
		<div class="lottery">
			<div class="lottery-item">
				<div class="lottery-start">

					<div class="box btn" ref="flashText" :class="{ 'flash': shouldFlash }" @click="startLottery">
						GO
					</div>

				</div>
				<ul>
					<li v-for="(item, i) in list" :class="i == index ? 'on' : ''" :key="i">
						<div class="box">
							<!-- <u-image  :src="item.img" mode=""></u-image> -->
							<!-- <image  :src="item.img" mode=""></image> -->
							<img :src="require('@/assets/' + item.img)" alt="奖品图片">
							<p>{{ item.title }}</p>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="notice-wrap">
			<vue-seamless-scroll :data="noticeList" :class-option="{step:0.2}">
				<div class="notice-list">
					<div class="notice-item" v-for="(item, index) in noticeList" :key="index">{{ item }}</div>
				</div>
			</vue-seamless-scroll>
		</div>
		<div class="invite">chia sẻ với bạn bè</div>
		<!-- <div class="blank"></div> -->
		<!-- 中奖弹窗 -->
		<div class="mask" v-if="showToast">
			<div class="lottery-alert">
				<img src="../assets/pop-bg.png" alt="" />
				<div class="pop-content">
					<img class="pop-img" mode="widthFix" src="../assets/lottery-money.png" />
					<div class="prize-content">
						2.024.000₫
					</div>
					<div class="btnsave" @click="download">Download APP</div>
				</div>
			</div>
		</div>
		<!-- 活动规则弹窗 -->
		<!-- 活动规则弹窗 -->
		<!-- <view class="pop-wrap  rules-wrap" v-if="showRules && !showToast">
    		<view class="mask"></view>
    		<view class="rules-alert">
    				<view class="pop-contents">
    						<scroll-view  scroll-y  class="scroll-view">
    							HHHH;JES;AFCIJWRME;MV;OREGJM
    						</scroll-view>
    						<view class="knowRules"  @click="showRules = false">xác nhận</view>
    				</view>
    		</view>
    	</view> -->
	</div>
</template>

<script>
	import navbar from '@/components/navbar'
	import vueSeamlessScroll from 'vue-seamless-scroll'
	import axios from 'axios';

	export default {
		name: 'HomeView',
		components: {
			vueSeamlessScroll,
			navbar
		},
		data() {
			return {
				// isStart: 1,
				score: 10, //消耗积分
				buttonClicked: false, // go 按钮是否被点击
				shouldFlash: false, // 控制文字是否闪烁
				timer: null, // 定时器
				list: [{
						img: 'lottery-percent.png',
						title: 'Giảm giá 80%'
					},
					{
						img: 'lottery-money.png',
						title: '2.024.000₫'
					},
					{
						img: 'lottery-thanks.png',
						title: 'Chúc may mắn lần sau'
					},
					{
						img: 'lottery-percent.png',
						title: 'Giảm giá 90%'
					},
					{
						img: 'lottery-money.png',
						title: '10K~50K'
					},
					{
						img: 'lottery-coupons.png',
						title: 'Voucher 50K'
					},
					{
						img: 'lottery-percent.png',
						title: 'Giảm giá 70%'
					},
					{
						img: 'lottery-coupons.png',
						title: 'Voucher 100K'
					}
				], //奖品1-9  
				index: -1, // 当前转动到哪个位置，起点位置
				count: 8, // 总共有多少个位置
				timer: 0, // 每次转动定时器
				speed: 200, // 初始转动速度
				times: 0, // 转动次数
				cycle: 50, // 转动基本次数：即至少需要转动多少次再进入抽奖环节
				prize: -1, // 中奖位置
				click: true,
				showToast: false, //显示中奖弹窗  
				showRules: false, //显示规则弹窗
				noticeList: []
				// noticeList:[
				// '恭喜获得1元红包',
				// '恭喜获得52元红包',
				// '恭喜获得3元红包',
				// '恭喜获得4元红包',
				// '恭喜获得5元红包',
				// ],
			}
		},
		onload() {

		},
		mounted() {
			this.getDiscountList()
			this.timer = setTimeout(() => {
				if (!this.buttonClicked) {
					this.startFlashing();
				}
			}, 3000);
		},
		beforeDestroy() {
			// 清除定时器，防止内存泄漏
			clearTimeout(this.timer);
		},
		methods: {
			getDiscountList() {
				axios.post('https://api.muamau.com/api/lottery/discountList', {}, ).then(res => {
					if (res.status == 200) {
						this.noticeList = res.data.data
					} else {
						uni.showToast({
							title: res.mess,
							duration: 2000,
							icon: "none",
						});
					}

				})
			},
			download(){
				this.showToast = false
				
				let userAgent = navigator.userAgent || navigator.vendor || window.opera
				
				  // 判断是否为iOS设备
				  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
				    window.location.href='https://apps.apple.com/us/app/muamau/id6587572457'
				  }
				
				  // 判断是否为Android设备
				  if (/android/i.test(userAgent)) {
				   window.location.href='https://play.google.com/store/apps/details?id=uni.UNI195FEF0&hl=es_GT'
				  }
		
			},
			
			handleButtonClick() {
				// 标记按钮被点击
				this.buttonClicked = true;
				// 如果已经设置了闪烁，清除定时器
				if (this.timer) {
					clearTimeout(this.timer);
				}
			},
			startFlashing() {
				// 开始闪烁
				this.shouldFlash = true;
				this.startLottery()
			},
			startLottery() {
				this.buttonClicked = true
				if (!this.click) {
					return
				}
				this.startRoll();
			},
			// 开始转动
			startRoll() {
				this.times += 1 // 转动次数
				this.oneRoll() // 转动过程调用的每一次转动方法，这里是第一次调用初始化 
				// 如果当前转动次数达到要求 && 目前转到的位置是中奖位置
				if (this.times > this.cycle + 10 && this.prize === this.index) {
					clearTimeout(this.timer) // 清除转动定时器，停止转动
					this.prize = -1
					this.times = 0
					this.speed = 200
					this.click = true;
					var that = this;
					setTimeout(() => {
						that.showToast = true;
					}, 500)
				} else {
					if (this.times < this.cycle) {
						this.speed -= 10 // 加快转动速度
					} else if (this.times === this.cycle) {
						// 随机获得一个中奖位置
						// const index = parseInt(Math.random() * 10, 0) || 0;  
						this.prize = 1; //中奖位置,可由后台返回 
						if (this.prize > 7) {
							this.prize = 7
						}
					} else if (this.times > this.cycle + 10 && ((this.prize === 0 && this.index === 7) || this.prize ===
							this.index + 1)) {
						this.speed += 110
					} else {
						this.speed += 20
					}
					if (this.speed < 40) {
						this.speed = 40
					}
					this.timer = setTimeout(this.startRoll, this.speed)
				}
			},

			// 每一次转动
			oneRoll() {
				let index = this.index // 当前转动到哪个位置
				const count = this.count // 总共有多少个位置
				index += 1
				if (index > count - 1) {
					index = 0
				}
				this.index = index
			},
		}
	}
</script>

<style>
	* {
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;
	}

	body {
		/* background: #9030cf;
  font-size: 14px; */
		width: 100%;
		/* height: 100vh; */
		/* overflow: hidden; */
		background: url(/src/assets/lottery-bg.jpg) no-repeat center;
		background-size: cover;
		/* 宽度和高度都覆盖容器，保持比例，可能会裁剪 */
		background-position: center;
		overflow: hidden;
		font-size: 14px;
		margin-bottom: 100rpx;
		height: 100vh;
	}


	img {
		border: 0px;
	}

	ul,
	li {
		list-style-type: none;
	}

	.lottery-box {
		overflow: hidden;
		padding-top: 40rpx;
	}

	.lottery-box .title {
		text-align: center;
		padding: 50px 0;
		font-size: 18px;
		color: #fff;
	}

	.lottery {
		animation: changeBg .5s ease infinite;
		overflow: hidden;
		padding: 20px;
		/* width: 400px; */
		margin: 0 auto;
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	@keyframes changeBg {
		0% {
			/* background-image: url(@/assets/k1.png); */
		}

		100% {
			/* background-image:url(@/assets/k2.png); */
		}
	}

	.notice-wrap {
		width: 328px;
		height: 35px;
		/* background: #fff; */
		color: #fff;
		background-image: url('/src/assets/boradcast-bg.png');
		/* background-repeat: no-repeat; */
		background-size: 100%;
		border-radius: 35px;
		overflow: hidden;
		text-align: center;
		margin: 0 auto;
		line-height: 35px;
	}

	.swiper-img {
		width: 305px;
		height: 78px;
		margin: 50px auto;
		display: flex;
		/* background-color: #fff; */
		/* background-image: url('/src/assets/lottery-title.png'); */
		background: url(/src/assets/lottery-title.png) no-repeat center;
		background-size: 305px 78px;
	}

	.rule-wrap {
		text-align: center;
		position: fixed;
		right: 0;
		top: 160px;
		padding: 0 10px 0 20px;
		background-color: rgba(255, 228, 167, 1);
		font-size: 15px;
		border-radius: 25px 0 0 25px;
		line-height: 25px;
	}

	.lottery .lottery-item {
		height: 340px;
		position: relative;
		margin-top: 10px;
		margin-left: 10px;
	}

	.lottery .lottery-item ul li {
		width: 33.33333333%;
		position: absolute;
		padding-right: 10px;
	}

	.lottery .lottery-item ul li:nth-child(2) {
		left: 33.33333333%;
	}

	.lottery .lottery-item ul li:nth-child(3) {
		left: 66.66666666%;
	}

	.lottery .lottery-item ul li:nth-child(4) {
		left: 66.66666666%;
		top: 110px;
	}

	.lottery .lottery-item ul li:nth-child(5) {
		left: 66.66666666%;
		top: 220px;
	}

	.lottery .lottery-item ul li:nth-child(6) {
		left: 33.33333333%;
		top: 220px;
	}

	.lottery .lottery-item ul li:nth-child(7) {
		left: 0;
		top: 220px;
	}

	.lottery .lottery-item ul li:nth-child(8) {
		left: 0;
		top: 110px;
	}

	.lottery .lottery-item ul li .box {
		height: 100px;
		position: relative;
		text-align: center;
		overflow: hidden;
		/* background-color: white; */
		background: url(/src/assets/bg2.png) no-repeat center;
		background-size: 100% 100%;

	}

	.lottery .lottery-item .lottery-start .box.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 40px;
		color: #fff;
		/* background: conic-gradient(from 190deg at 57% 40%, #8b6ff4 -96deg, #615ced 41deg, #615ced 207deg, #8b6ff4 264deg, #615ced 401deg); */
		background: linear-gradient(180deg, #f387bc, #a26fed);
	}

	.lottery .lottery-item ul li .box img {
		display: block;
		height: 50px;
		width: 50px;
		margin: 0 auto;
		margin-top: 10px;
		margin-bottom: 5px;
	}

	.lottery .lottery-item ul li .box p {
		color: #708ABF;
		white-space: break-spaces;
		/* overflow: hidden; */
		text-overflow: ellipsis;
		font-size: 14px;
		line-height: 14px;
	}

	.lottery .lottery-item ul li.on .box {
		background: url(/src/assets/bg1.png) no-repeat center;
		background-size: 100% 100%;
	}

	.lottery .lottery-item ul li.on .box p {
		color: #fff;
	}

	.lottery .lottery-item .lottery-start {
		position: absolute;
		left: 33.33333333%;
		width: 33.33333333%;
		top: 110px;
		padding-right: 10px;
	}

	.lottery .lottery-item .lottery-start .box {
		height: 100px;
		font-size: 14px;
		color: #fff;
		cursor: pointer;
		text-align: center;
		overflow: hidden;
		background: url(/src/assets/bg1.png) no-repeat center;
		background-size: 100% 100%;
	}

	.lottery .lottery-item .lottery-start .box p b {
		font-size: 40px;
		margin-top: 16px;
		margin-bottom: 15px;
		line-height: 30px;
		display: block;
	}

	.lottery .lottery-item .lottery-start .box:active {
		opacity: 0.7;
	}

	.lottery .lottery-item .lottery-start .box.gray {
		background: url(/src/assets/bg3.png) no-repeat center;
		background-size: 100% 100%;
	}

	.lottery .lottery-item .lottery-start .box.gray p {
		color: #708ABF;
		font-weight: bold;
	}

	.invite {
		text-align: center;
		width: 154px;
		border-radius: 42px;
		line-height: 42px;
		margin: 20px auto 10px;
		/* margin-bottom: 100px; */
		background: #9741ed;
		color: #fff;
	}

	.blank {
		height: 100px;
	}

	.mask {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
		position: fixed;
		overflow: hidden;
		z-index: 222;
		top: 0;
		left: 0;
	}

	@keyframes slideIn {
		from {
			transform: scale(.8) translateY(-50%);
			opacity: 0;
		}

		to {
			transform: scale(1) translateY(-50%);
			opacity: 1;
		}
	}

	.lottery-alert {
		z-index: 10000;
		width: 90%;
		position: fixed;
		left: 0;
		right: 0;
		margin: auto;
		top: 50%;
		animation: slideIn 0.3s cubic-bezier(0.59, -0.1, 0.47, 1.73);
		transform: translateY(-50%);
	}

	.lottery-alert h1 {
		font-size: 18px;
		font-weight: bold;
		color: #D92B2F;
	}

	.lottery-alert img {
		/* display: block;
  width: 100%;
  margin: 0 auto; */
		display: block;
		width: 100%;
		height: 40vh;
		margin: 0 auto;
	}

	.lottery-alert h2 {
		font-weight: normal;
		color: #D92B2F;
		font-size: 15px;
		padding-top: 15px;
	}

	.lottery-alert p {
		color: #666;
		font-size: 16px;
		padding-top: 5px;
	}

	/* .lottery-alert .btnsave {
  width:60%;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  padding: 0 43px;
  margin: 0 auto;
  text-align: center;
  background: linear-gradient(180deg, #C99AFC, #8E36FC);
  box-shadow: 0px 0px 10px 0px rgba(151,67,253,0.4);
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  text-shadow: -1px 3px 5px rgba(0,0,0,0.5);
} */

	/* .pop-content{
  position: absolute;
  left: 12%;
  top: 25%;
  bottom: 10%;
  right: 10%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
} */

	.pop-content {
		position: absolute;
		width: 80%;
		left: 12%;
		top: 25%;
		bottom: 10%;
		right: 10%;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		.pop-img {
		
			width: 40%;
			height: 50%;
			margin: 0 auto;
			transform: translateY(40rpx);
		
		}
		.prize-content {
			text-align: center;
			color: orange;
			font-weight: 600;
			font-size: 32px;
		}
		
		.btnsave {
			width: 60%;
			height: 32px;
			line-height: 32px;
			border-radius: 32px;
			padding: 0 43rpx;
			margin: 0 auto;
			text-align: center;
			background: linear-gradient(180deg, #C99AFC, #8E36FC);
			box-shadow: 0 0 10rpx 0rpx rgba(151, 67, 253, 0.4);
			font-weight: 500;
			font-size: 32rpx;
			color: #FFFFFF;
			text-shadow: -1rpx 3rpx 5rpx rgba(0, 0, 0, 0.5);
		}
	}

	

	

	.rules-wrap {
		.pop-contents {

			margin: 0 auto;
			width: 80%;
			height: auto;
			background-color: white;
			border-radius: 10rpx;
			padding: 40rpx;
			/* height: 400rpx; */

		}

		.knowRules {
			background: #6638b4;
			color: white;
			text-align: center;
			margin: 0 auto;
			margin-top: 40rpx;
			border: 1px solid lightgray;
			width: 250rpx;
			height: 70rpx;
			line-height: 70rpx;
			border-radius: 20rpx;
		}
	}

	.rules-alert {
		z-index: 9999;
		width: 90%;
		position: fixed;
		left: 0;
		right: 0;
		margin: auto;
		top: 50%;
		animation: slideIn 0.3s cubic-bezier(0.59, -0.1, 0.47, 1.73);
		transform: translateY(-50%);

	}

	.rule-btn {
		color: white;
		padding: 0 18rpx;
		font-size: 20rpx;
		border-radius: 36rpx;
		display: flex;
		justify-content: center;
		line-height: 52rpx;
		border: 1rpx solid #fff;
		margin: 0 auto;
		margin-top: 50rpx;
		width: 250rpx;
	}

	.scroll-view {
		color: black;
		height: 300px;
		/* 设置滚动区域的高度 */
		border: 1px solid #ccc;
		border-radius: 4px;
		overflow: hidden;
		white-space: pre-wrap;
		/* 保留空白符序列，但是正常地进行换行 */
		word-break: break-all;
		/* 在单词内换行 */
		overflow-wrap: break-word;
		/* 在长单词或 URL 地址内部进行换行 */
	}

	.flash {
		animation: flash 0.5s 4;
		/* 2秒内闪烁两次，只播放一次 */
	}

	@keyframes flash {

		0%,
		100% {
			opacity: 1;
		}

		25%,
		75% {
			opacity: 0.25;
		}
	}
</style>